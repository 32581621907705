























































































































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component } from "vue-property-decorator";

import StripePaymentElementForm from "../components/StripePaymentElementForm.vue";
import PurchaseOverview from "../components/PurchaseOverview.vue";
import { Product } from "@/core/models";
import { EUVat, EUVatRate } from "@/core/plugins/eu-vat-rates";
import { paymentSettings } from "@/settings";

@Component({
  components: {
    StripePaymentElementForm,
    PurchaseOverview,
  },
})
export default class SubscriptionPurchase extends Vue {
  @Getter("products/retrieved") retrieved!: boolean;
  @Getter("products/products") products!: Product[];
  @Getter("profile/getUserName") myName!: string;
  @Getter("profile/customerId") customerId!: string;
  @Action("products/get") getProducts!: Function;

  step = 1;
  paymentElementRef: HTMLFormElement | null = null;
  coupon: any | null = null;
  vatId = "";
  companyName = "";
  country = "DE";
  euVat: EUVatRate = EUVat.rates.DE;
  buyAsCompany = false;
  vatValidated = false;
  reverseCharge = false;
  checkoutEnabled = false;

  get name() {
    return this.myName;
  }

  get pricingOptions() {
    const { tier, cycle } = this.$route.query;
    if (!tier || !cycle) this.$router.push("/subscription/pricing");
    return {
      tier: this.addPrefix(tier as string, "price_"),
      cycle: cycle as string,
    };
  }

  get isYearly(): boolean {
    return this.pricingOptions.cycle === "year";
  }

  get currentProduct() {
    // Get product by price id (tier)
    return this.products.filter(product => {
      return (
        product.monthlyPrice === this.pricingOptions.tier ||
        product.yearlyPrice === this.pricingOptions.tier
      );
    })[0];
  }

  get vatRate() {
    console.debug(this.buyAsCompany, this.euVat, this.vatValidated);
    if (this.buyAsCompany && this.euVat && this.vatValidated) {
      if (this.euVat.country === "Germany") {
        console.log("No Reverse Charge in DE");
        this.reverseCharge = false;
        return this.euVat.standard_rate as number;
      }
      if (!this.euVat.country) {
        console.log("No Reverse Charge (outside EU) in", this.country);
        this.reverseCharge = false;
        return 0;
      }
      console.log("Apply Reverse Charge in", this.country);
      this.reverseCharge = true;
      return this.euVat.standard_rate as number;
    }
    console.log("No valid Company - Apply default tax in", this.country);
    this.reverseCharge = false;
    return (this.euVat.standard_rate as number) || 0;
  }

  // TODO: Replace reverseCharge checks with taxExempt === "reverse"
  get taxExempt() {
    if (this.buyAsCompany && this.euVat && this.vatValidated) {
      if (this.euVat.country === "Germany")
        //console.log("No Reverse Charge in DE");
        return "none";

      if (!this.euVat.country)
        //console.log("No Reverse Charge (outside EU) in", this.country);
        return "exempt";

      //console.log("Apply Reverse Charge in", this.country);
      return "reverse";
    }
    if (this.euVat.country)
      //console.log("Normal Vat in", this.country);
      return "none";

    //console.log("No Vat (outside EU) in", this.country);
    return "exempt";
  }

  get grossAmount() {
    if (this.retrieved)
      return !this.isYearly
        ? this.currentProduct.unitAmountMonthly
        : this.currentProduct.unitAmountYearly;
    else return 0;
  }

  get netOriginal() {
    if (paymentSettings.vatIncluded)
      return this.grossAmount / (1 + this.vatRate / 100);

    return this.grossAmount;
  }

  get grossOriginal() {
    if (paymentSettings.vatIncluded) return this.grossAmount;

    return this.grossAmount + (this.grossAmount / 100) * this.vatRate;
  }

  get netTotal() {
    if (paymentSettings.vatIncluded)
      return this.discountPrice
        ? this.discountPrice / (1 + this.vatRate / 100)
        : this.grossAmount / (1 + this.vatRate / 100);

    return this.discountPrice ? this.discountPrice : this.grossAmount;
  }

  get grossTotal() {
    if (paymentSettings.vatIncluded)
      return this.discountPrice ? this.discountPrice : this.grossAmount;

    return this.discountPrice
      ? this.discountPrice + (this.discountPrice / 100) * this.vatRate
      : this.grossAmount + (this.grossAmount / 100) * this.vatRate;
  }

  get discountPrice(): number {
    if (!this.coupon) return 0;

    const { amount_off, percent_off } = this.coupon;
    const price = this.grossAmount;
    const discount = amount_off ? amount_off : price * (percent_off / 100);
    return price - discount;
  }

  formatPrice(amount: number) {
    return `€${amount.toFixed(2)}`;
  }

  formatAmount(amount: number) {
    // Use on all calculations with currency amounts per value to prevent 3rd decimal (.xx5) rounding issues of percent/tax calculations
    return parseFloat((amount / 100).toFixed(2));
  }

  addPrefix = (string: string, prefix: string) => {
    return prefix + string;
  };

  /*
  isDecimal(amount: number) {
    // Calculate the difference between n and its floor value, and check if it is not equal to zero.
    return amount - Math.floor(amount) !== 0;
  }
  */

  created() {
    if (!this.retrieved) this.getProducts();
  }
}
